import * as React from "react";
export const routes = [
  /* -------------------------------------------------------------------------- */
  /*                           Dashboard Routes Starts                          */
  /* -------------------------------------------------------------------------- */
  {
    path: "/",
    Component: React.lazy(() => import("../../Pages/Dashboard/UserDashboard")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/userDashboard",
    Component: React.lazy(() => import("../../Pages/Dashboard/UserDashboard")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/sales",
    Component: React.lazy(() => import("../../Pages/Dashboard/Sales")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/crm",
    Component: React.lazy(() => import("../../Pages/Dashboard/Crm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/marketing",
    Component: React.lazy(() => import("../../Pages/Dashboard/Marketing")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/procurment",
    Component: React.lazy(() => import("../../Pages/Dashboard/Procurment")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/civil",
    Component: React.lazy(() => import("../../Pages/Dashboard/Civil")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/civil-progress",
    Component: React.lazy(() => import("../../Pages/Dashboard/CivilProgress")),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/dashboard/civil-process-progress',
  // 	Component: React.lazy(() => import('../../Pages/Dashboard/CivilProcessProgress')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  {
    path: "/dashboard/pre-sales",
    Component: React.lazy(() => import("../../Pages/Dashboard/PreSales")),
    exact: true,
    isNavbar: true
  },
  /* -------------------------------------------------------------------------- */
  /*                            Dashboard Routes Ends                           */
  /* -------------------------------------------------------------------------- */
  {
    path: "/login",
    Component: React.lazy(() => import("../../Pages/Login")),
    exact: true,
    isNavbar: false
  },

  /* -------------------------------------------------------------------------- */
  /*                            Reports Routes Starts                            */
  /* -------------------------------------------------------------------------- */
  {
    path: "/profitable-report",
    Component: React.lazy(() => import("../../Pages/Reports")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/nextHearing/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/CourtCase")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/courtCase/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/CourtReminder")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/liaison-reports/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/Liaison")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/liaison-fallowups-reports/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/Task")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/liaison_kns_report/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/LiaisonKnsReport")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/liaison_shc_report/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/liaisonShcReport")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/liaison_all_report/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/LiaisonAllReport")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/liaison_own_report/:reportDate/:processOwnerID",
    Component: React.lazy(() => import("../../Pages/Reports/LiaisonOwnReport")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/profitable",
    Component: React.lazy(() => import("../../Pages/Reports/Profitable")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/task-wise-quantity/:reportDate",
    Component: React.lazy(() => import("../../Pages/Reports/TaskWiseQuantity")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/grn-on-time-kpi-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/GRNOnTimeKPIReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/vendor-lead-time-report",
    Component: React.lazy(() => import("../../Pages/Reports/VendorLeadTime")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/purchase-invoice-kpi-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PurchaseInvoiceKPI")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/purchase-invoice-kpi-detail-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PurchaseInvoiceKPIDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/material-quality-rejection-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/MaterialQualityRejection")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/material-quality-rejection-kpi-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/MaterialQualityRejectionKPI")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/indent-po-report",
    Component: React.lazy(() => import("../../Pages/Reports/IndentPO")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/on-time-delivery-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/OnTimeDeliveryKPI")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/on-time-delivery-detail-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/OnTimeDeliveryKPIDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/procurement-kpi-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/ProcurementKPIReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/Liaison-Process-Plan-Kpi",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LiaisonProcessPlanKpi")
    ),
    exact: true,
    isNavbar: true
  },

  //Team Report
  {
    path: "/report/sales-team-review-report",
    Component: React.lazy(() => import("../../Pages/Reports/SalesTeamReview")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/detail",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/SalesReviewDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/actual-call",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ActualFollowUp")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/planned-site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/PlannedSiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/planned-call",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/PlannedCall")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/actual-site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ActualSiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/prospective-client",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ProspectiveClient")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/TotalCancellation")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/cancellation-previous",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/CancellationPrevious")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/booking",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/TotalBooking.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/gross-sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ActualGrossSales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/hot-prospective-client",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/HotProspectiveClient")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/planned-cp-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/PlannedCPMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/actual-cp-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ActualCPMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/planned-client-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/PlannedClientMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/actual-client-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ActualClientMeeting")
    ),
    exact: true,
    isNavbar: true
  },

  //Review Report
  {
    path: "/report/sales-review-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/index")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/actual-call",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ActualFollowUp")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/planned-site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/PlannedSiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/planned-call",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/PlannedCall")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/actual-site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ActualSiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/prospective-client",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ProspectiveClient")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/TotalCancellation")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/cancellation-previous",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/CancellationPrevious")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/booking",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/TotalBooking.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/gross-sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ActualGrossSales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/hot-prospective-client",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/HotProspectiveClient")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/planned-cp-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/PlannedCPMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/actual-cp-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ActualCPMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/planned-client-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/PlannedClientMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/actual-client-meeting",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ActualClientMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/enquiry-follow-up",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/EnquiryFollowUp")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_sales_report",
    Component: React.lazy(() => import("../../Pages/Reports/KPIsalesReport")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_sales_report/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIsalesReport/reviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_pre_sales_report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIpreSalesReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_pre_sales_report/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIpreSalesReport/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_pre_sales_crm_report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPISalesCrmReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi-pre-sales-summary",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIPreSalesSummary")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi-pre-sales-summary/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIPreSalesSummary/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/KPI_Sales_Summary_Report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPISalesSummaryReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/KPI_Sales_Summary_Report/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPISalesSummaryReport/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_telecalling_report",
    Component: React.lazy(() => import("../../Pages/Reports/KPITeleCalling")),
    exact: true,
    isNavbar: true
  },

  //   Nikhil 20 july

  {
    path: "/report/kpi_pre_sales_report/totalLeadAssigned",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIpreSalesReport/totalLeadAssinged")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_pre_sales_report/qualifiedLead",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIpreSalesReport/qualifiedLead")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_pre_sales_report/totalSiteVisit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIpreSalesReport/totalSiteVisit")
    ),
    exact: true,
    isNavbar: true
  },

  // Nithish 20 july

  {
    path: "/report/kpi_sales_report/total_site_visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIsalesReport/TotalSiteVisit.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_sales_report/total_booking",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIsalesReport/TotalBooking.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_sales_report/total_cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIsalesReport/TotalCancellation.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_sales_report/total_booking",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIsalesReport/TotalBooking.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/kpi_sales_report/total_cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/KPIsalesReport/TotalCancellation.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/lead-campaign-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadCampaignReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/lead-campaign-report/view",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadCampaignReport/LeadCampaignReportDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/lead-campaign-report/total-lead",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadCampaignReport/TotalLead")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/lead-campaign-report/qualified-lead",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadCampaignReport/TotalQualifiedLead")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/lead-campaign-report/site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadCampaignReport/TotalSiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/lead-campaign-report/booking",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadCampaignReport/TotalBooking")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/lead-campaign-report/total-cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadCampaignReport/TotalCancellation")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/sales-report",
    Component: React.lazy(() => import("../../Pages/Reports/SalesReport")),
    exact: true,
    isNavbar: true
  },

  /* -------------------------------------------------------------------------- */
  /*                             Reports Routes Ends                            */
  /* -------------------------------------------------------------------------- */

  {
    path: "/liaison",
    Component: React.lazy(() => import("../../Pages/Liaison")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/progress",
    Component: React.lazy(() => import("../../Pages/Civil/ProjectProgress")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/budget",
    Component: React.lazy(() => import("../../Pages/Civil/ProjectBudget")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/resource-nmr-planning",
    Component: React.lazy(() => import("../../Pages/Civil/ManPowerPlanning")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/resource-nmr-planning-details",
    Component: React.lazy(() =>
      import("../../Pages/Civil/ManPowerDetailPlanning")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/resource-machine-planning",
    Component: React.lazy(() => import("../../Pages/Civil/MachinePlanning")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/resource-machine-planning-details",
    Component: React.lazy(() =>
      import("../../Pages/Civil/MachinDetailPlanning")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/resource-type-planning",
    Component: React.lazy(() => import("../../Pages/Civil/MachinePlanning")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/resource-planning",
    Component: React.lazy(() => import("../../Pages/Civil/ResourcePlanning")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/proposed",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProposedProject")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/up-coming",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/UpcomingProject")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/up-coming/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/UpcomingProject/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/up-coming/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/UpcomingProject/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/on-going",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/OngoingProject")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/on-going/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/OngoingProject/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/on-going/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/OngoingProject/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/completed",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CompletedProject")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/completed/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CompletedProject/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/completed/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CompletedProject/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/dropped",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DroppedProject")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/dropped/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DroppedProject/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/dropped/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DroppedProject/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/proposed/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProposedProject/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/flow",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFlow")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/inspection",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectInspection")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/plan",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectPlan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/plan/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectPlan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/task-plan",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectTaskPlan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/task-plan/supplementary-deeds",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/ProjectTaskPlan/SupplementaryDeeds"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/task-plan/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectTaskPlan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/approval-pending",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectTaskApprovalPending")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectSurveyNo")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file/ProjectSurveyNoForm",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/ProjectSurveyNo/ProjectSurveyNoForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectSurveyNo/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file/Review",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectSurveyNo/Review")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-process-plan",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonProcessPlan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-process-plan/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonProcessPlan/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-process-plan/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonProcessPlan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-process-plan-application/applicationSplitModal",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiasonProcessPlanApplication/ApplicationSplitModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-process-plan/StartDateEditModal",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiasonProcessPlan/StartDateEditModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-process-plan-application",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonProcessPlanApplication")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-process-plan-application/view",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiasonProcessPlanApplication/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-task-plan",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonTaskPlan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-task-plan/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonTaskPlan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-task-plan/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonTaskPlan/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-documentation",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonDocumentation")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-documentation/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonDocumentation/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-documentation/documentCheckList",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiasonDocumentation/DocumentCheckListForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-documentation/ownerAssign",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiasonDocumentation/OwnerAssignForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-documentation-approval",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiasonDocumentationApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-documentation-approval/approval",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiasonDocumentationApproval/ApprovalModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-survey/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectSurveyNo/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/task-plan/approvals",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectTaskPlan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/survey-no-pending",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SurveyNumberTaskPending")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/survey-no/approvals",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/SurveyNumberTaskPending/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-Process-Applicability",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonProcessApplicability/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/task-plan-document",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/TaskPlanDocument/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-profitable",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/Profitable")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/notifications",
    Component: React.lazy(() => import("../../Pages/Notifications")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/milestone",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/MileStone")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/authorization",
    Component: React.lazy(() => import("../../Pages/Authorization")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/authorization/new",
    Component: React.lazy(() => import("../../Pages/Authorization/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-owner",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandOwner")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-owner/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandOwner/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-owner/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandOwner/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-owner/details",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandOwner/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-owner/newAccounts",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandOwner/landOwnerAccountModal")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-owner/accountEdit",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LandOwner/landOwnerAccountEditModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file-acquisition-info",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file-acquisition-info/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file-acquisition-info/approvals",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file-acquisition-info/editForm",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/editForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-broker",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandBroker")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-broker/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandBroker/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-broker/details",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandBroker/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-broker/accountEdit",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LandBroker/landBrokerAccountEditModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-broker/newAccounts",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LandBroker/landBrokerAccountModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-broker/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandBroker/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/FeasibilityReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/FeasibilityReport/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/FeasibilityReport/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report/approval",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/FeasibilityReport/Approval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report/printPdf",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/FeasibilityReport/printPdf")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report-general",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/FeasibilityReportGeneral")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report-general/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/FeasibilityReportGeneral/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/feasibility-report-general/view",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/FeasibilityReportGeneral/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-fund",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFund")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-fund/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFund/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-fund/Review",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFund/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-broker",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectBroker")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-broker/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectBroker/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-payment",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandPayment")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-payment/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandPayment/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-payment/brokerPayment",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandPayment/brokerPaymentForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-payment/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandPayment/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-payment/governmentFeeForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandPayment/governmentFeeForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-payment/advancePaymentForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandPayment/advancePaymentForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/basic-document",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/BasicDocument")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/basic-document/form",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/BasicDocument/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/basic-document/basicDocumentForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/BasicDocument/basicDocumentForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/account-payable",
    Component: React.lazy(() => import("../../Pages/Accounts/AccountPayable")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/account-payable/new",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/AccountPayable/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/account-payable/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/AccountPayable/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/paid-account",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaidAccount/index")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/paid-account/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaidAccount/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/project-progress",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/CivilProject/ProjectProgress")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/project-progress/projectProgressDetail",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/CivilProject/projectProgressDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/project-progress/projectProgressTaskDetail",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/CivilProject/taskProgressDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/proposed/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProposedProject/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/proposed/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProposedProject/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-company-map",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectCompanyMap")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-company-map/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectCompanyMap/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-company-map/approvals",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectCompanyMap/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonReport")
    ),
    exact: true,
    isNavbar: true
  },
  // {
  //   path: "/test/test",
  //   Component: React.lazy(() => import("../../Pages/Test/Test")),
  //   exact: true,
  //   isNavbar: true
  // },
  // {
  //   path: "/test/test2",
  //   Component: React.lazy(() => import("../../Pages/Test/Test2")),
  //   exact: true,
  //   isNavbar: true
  // },
  // {
  //   path: "/test/testPrint",
  //   Component: React.lazy(() => import("../../Pages/TestPDFPrint")),
  //   exact: true,
  //   isNavbar: true
  // },
  {
    path: "/profile",
    Component: React.lazy(() => import("../../Pages/Profile/UserProfile")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/profile/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Profile/UserProfile/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/birthdayWish",
    Component: React.lazy(() => import("../../Pages/Greetings/Birthday")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/workAnniversary",
    Component: React.lazy(() =>
      import("../../Pages/Greetings/WorkAnniversary")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/consolidated-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ConsolidatedReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/consolidated-report/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ConsolidatedReport/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/documentation-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DocumentationReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-task",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfTask")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-task/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfTask/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-task/Review",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfTask/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-summary",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfSummary")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-query",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfQuery")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-query/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfQuery/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-query/Review",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfQuery/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-dispute",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandDispute")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-dispute/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandDispute/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-dispute/Review",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandDispute/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-dispute/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandDispute/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/court-case/",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CourtCase")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/court-case/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CourtCase/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/court-case/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CourtCase/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/court-case/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CourtCase/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-investment",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectInvestment")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-query",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectQuery")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-query/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectQuery/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/project-summary",
    Component: React.lazy(() => import("../../Pages/Dashboard/ProjectSummary")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/document-rework-report",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/DocumentReworkReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/project-task-report",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/ProjectTaskReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/training",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/Training")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/training/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/Training/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-dispute/landDisputeForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandDispute/landDisputeForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/court-case/courtCaseForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CourtCase/courtCaseForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/apf-task/apfTaskForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ApfTask/apfTaskForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/projectNamingForm",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/ProposedProject/projectNamingForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/trainingContent",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/TraningContent")
    ),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/business-development/liaison-payment',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/new',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/form')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/view',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/ReviewForm')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/addPaymentRequest',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/addPaymentRequest')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/approval',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Approval')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/issue',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Issue')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/settlementRequest',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementRequest')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/settlementApproval',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementApproval')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/settlementSettle',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementSettle')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/liaison-payment/detail',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Detail')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  {
    path: "/business-development/loan",
    Component: React.lazy(() => import("../../Pages/BusinessDevelopment/Loan")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/loan/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/Loan/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/investment",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/Investment")
    ),
    exact: true,
    isNavbar: true
  },
  // Master
  {
    path: "/business-development/liaison-process-list",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonProcessMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-list/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonProcessMaster/Form")
    ),
    exact: true,
    isNavbar: true
  },
  // Master End
  {
    path: "/business-development/liaison-process-plan",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonProcessPlan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonProcessPlan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan/StartDateEditModal",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonProcessPlan/StartDateEditModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan/SurveyAddModel",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonProcessPlan/SurveyAddModel"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonProcessPlan/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan/applicationSplitModal",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonProcessPlan/ApplicationSplitModal"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-task-plan",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonTaskPlan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-task-plan/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonTaskPlan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-task-plan/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonTaskPlan/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan-approval",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonProcessPlanApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan-approval/view",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonProcessPlanApproval/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan-approval/view",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonProcessPlanApproval/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison/legal-task",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LegalTask")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison/legal-task/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LegalTask/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison/legal-task/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LegalTask/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison/legal-task/legalTaskForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LegalTask/legalTaskForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison/legal-task/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LegalTask/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/admin/newsletter",
    Component: React.lazy(() => import("../../Pages/Admin/Newsletter")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/admin/newsletter/new",
    Component: React.lazy(() => import("../../Pages/Admin/Newsletter/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/admin/ProjectTaskOwnerUpdate",
    Component: React.lazy(() =>
      import("../../Pages/Admin/Newsletter/ProjectTaskOwnerUpdate")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/ProjectSurveyMapping",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/ProjectSurveyMapping")
    ),
    exact: true,
    isNavbar: true
  },
  // ---dashboard new --
  {
    path: "/dashboard/bd",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/BusinessDevelopment")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/liaison",
    Component: React.lazy(() => import("../../Pages/Dashboard/Liaison")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/documentation",
    Component: React.lazy(() => import("../../Pages/Dashboard/Documentation")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/ProjectFileDeed",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFileDeeds")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/ProjectFileDeed/ReviewForm",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/ProjectFileDeeds/ProjectFileDeedReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/ProjectFileDeed/Form",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFileDeeds/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/bd-consolidated-report",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/ConsolidatedReport/BDConsolidatedReport"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/pendingTask",
    Component: React.lazy(() => import("../../Pages/Dashboard/PendingTask")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/Project")
    ),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/master/bdMaster/ProjectTaskDependency',
  // 	Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/master/bdMaster/ProjectTaskDependency/new',
  // 	Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency/form')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/master/bdMaster/ProjectTaskDependency/edit',
  // 	Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency/Reviewform')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // _______________________Sadanand______________
  {
    path: "/business-development/toBeAcquiredFile",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ToBeAcquiredFile")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/toBeAcquiredFile/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ToBeAcquiredFile/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/toBeAcquiredFile/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ToBeAcquiredFile/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-process-plan/bulkOwnerAssign",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonProcessPlan/LiaisonProcessOwnerAssignForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  // unused
  // {
  // 	path: '/business-development/loan-process',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanProcess')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/loan-process/new',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanProcess/Form')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/loan-process/view',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanProcess/ReviewForm')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/loan-task-plan',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanTaskPlan')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  // {
  // 	path: '/business-development/loan-task-plan/view',
  // 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanTaskPlan/ReviewForm')),
  // 	exact: true,
  // 	isNavbar : true
  // },
  {
    path: "/business-development/liaison-process-plan/updateBulk",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonProcessPlan/LiaisonProcessUpdateBulkForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/delayed-process",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DelayedProcess")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/delayed-process/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DelayedProcess/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  //   BD Master
  {
    path: "/bd-masters/BdIssueType",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BdIssueType")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/BdIssueType/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BdIssueType/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/BdIssueType/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BdIssueType/Reviewform")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonProcessCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonProcessCategory/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonProcessCategory/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessCategory/Reviewform")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/ProjectTask",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTask")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/ProjectTask/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTask/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LiaisonProcess/Edit-Review-form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTask/EditReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskCategory/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskCategory/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskCategory/Reviewform")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskDependency",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskDependency")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskDependency/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskDependency/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskDependency/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskDependency/Reviewform")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/PlanningAuthority",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/PlanningAuthority")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/PlanningAuthority/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/PlanningAuthority/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/PlanningAuthority/Reviewform",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/PlanningAuthority/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonTaskCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonTaskCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonTaskCategory/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonTaskCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonTaskCategory/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonTaskCategory/Reviewform")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LiaisonTask",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonTask")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LiaisonTask/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonTask/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LiaisonTask/Review-form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonTask/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/delayed-liaison-task-plan",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DelayedLiaisonTaskPlan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/delayed-liaison-task-plan/view",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/DelayedLiaisonTaskPlan/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project-file/split-sy-no",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectSurveyNo/SurveySplitModal")
    ),
    exact: true,
    isNavbar: true
  },
  // HR  module
  // {
  // 	path: "/HR/PaymentRequestedBy",
  // 	Component: React.lazy(() =>
  // 	  import("../../Pages/HR/PettyCash/PaymentRequestedBy")
  // 	),
  // 	exact: true,
  // 	isNavbar: true,
  //   },
  //   {
  // 	path: "/HR/PaymentRequestedBy/form",
  // 	Component: React.lazy(() => import("../../Pages/HR/PettyCash/form")),
  // 	exact: true,
  // 	isNavbar: true,
  //   },
  //   {
  // 	path: "/HR/Paymentissue",
  // 	Component: React.lazy(() =>
  // 	  import("../../Pages/HR/PettyCash/PaymentIssueby")
  // 	),
  // 	exact: true,
  // 	isNavbar: true,
  //   },
  //   {
  // 	path: "/HR/SettlementApprovalsBy",
  // 	Component: React.lazy(() =>
  // 	  import("../../Pages/HR/PettyCash/SettlementApprovalsBy")
  // 	),
  // 	exact: true,
  // 	isNavbar: true,
  //   },
  {
    path: "/HR/VehicleFuelRequest",
    Component: React.lazy(() => import("../../Pages/HR/VehicleFuelRequest")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/VehicleFuelRequest/new",
    Component: React.lazy(() =>
      import("../../Pages/HR/VehicleFuelRequest/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/VehicleService",
    Component: React.lazy(() => import("../../Pages/HR/VehicleService")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/VehicleService/new",
    Component: React.lazy(() => import("../../Pages/HR/VehicleService/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/VehicleService/update",
    Component: React.lazy(() =>
      import("../../Pages/HR/VehicleService/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/VehicleService/print",
    Component: React.lazy(() =>
      import("../../Pages/HR/VehicleService/VechialServicePrint")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/AttendanceReport",
    Component: React.lazy(() => import("../../Pages/HR/EmployeeAttendance")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/AttendanceReport/new",
    Component: React.lazy(() =>
      import("../../Pages/HR/EmployeeAttendance/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/EmployeeCheckin",
    Component: React.lazy(() => import("../../Pages/HR/EmployeeCheckin")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/EmployeeCheckin/Maps",
    Component: React.lazy(() =>
      import("../../Pages/HR/EmployeeCheckin/googleMaps")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/DailyAttendanceReport",
    Component: React.lazy(() =>
      import("../../Pages/HR/EmployeeAttendanceDetails")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/LeaveApplication",
    Component: React.lazy(() => import("../../Pages/HR/LeaveApplication")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/LeaveApplication/new",
    Component: React.lazy(() => import("../../Pages/HR/LeaveApplication/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/AddAttendance",
    Component: React.lazy(() => import("../../Pages/HR/ImportAttendance")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/AttendanceExport",
    Component: React.lazy(() => import("../../Pages/HR/ExportAttendance")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/EmployeeLoginList",
    Component: React.lazy(() =>
      import("../../Pages/HR/EmployeeLocationApprovals")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/task",
    Component: React.lazy(() => import("../../Pages/HR/Task")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/task/addtask",
    Component: React.lazy(() => import("../../Pages/HR/Task/addtask")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/task/view",
    Component: React.lazy(() => import("../../Pages/HR/Task/ReviewForm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/task/edit",
    Component: React.lazy(() => import("../../Pages/HR/Task/editForm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/LeaveSummary",
    Component: React.lazy(() => import("../../Pages/HR/EmployeeLeaveSummary")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/CompOffLeaveSummary",
    Component: React.lazy(() =>
      import("../../Pages/HR/EmployeeCompOffLeaveSummary")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/plan-approval-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/PlanApprovalReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-master/LiaisonProcess",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcess")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-master/LiaisonProcess/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcess/LiaisonProcessForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-master/LiaisonProcess/Edit-form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcess/EditReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan",
    Component: React.lazy(() => import("../../Pages/Civil/CivilProjectPlan")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/bd-kpi-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/BD_KPI_REPORT")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan/new",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectPlan/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan/team-planner",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectPlan/TeamPlanner")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan/locations",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectPlan/Locations")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan-wbs",
    Component: React.lazy(() =>
      import("../../Pages/Civil/WorkBreakDownStructure")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/proposed/project-merger",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProposedProject/ProjectMerger")
    ),
    exact: true,
    isNavbar: true
  },
  //Activity not in use
  {
    path: "/civil/civil-project-task-plan-activity",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectTaskPlanActivity")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-plan-activity/new",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectTaskPlanActivity/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-plan-activity/quantityPlanning",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectTaskPlanActivity/QuantityPlanning")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-plan-activity/materialPlanning",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectTaskPlanActivity/MaterialPlanning")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-plan-activity/resourceIdentification",
    Component: React.lazy(() =>
      import(
        "../../Pages/Civil/CivilProjectTaskPlanActivity/ResourceIdentification"
      )
    ),
    exact: true,
    isNavbar: true
  },
  // Nikhil
  {
    path: "/bd-masters/PaymentMilestone",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/PaymentMilestone/index")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/PaymentMilestone/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/PaymentMilestone/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/PaymentMilestone/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/PaymentMilestone/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan-wbs/new",
    Component: React.lazy(() =>
      import("../../Pages/Civil/WorkBreakDownStructure/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskRejectionReason",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskRejectionReason")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskRejectionReason/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskRejectionReason/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskRejectionReason/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskRejectionReason/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/AgreementType",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/AgreementType")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/AgreementType/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/AgreementType/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/AgreementType/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/AgreementType/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtCaseType",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtCaseType")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtCaseType/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtCaseType/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtCaseType/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtCaseType/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtEstablishment",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtEstablishment")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtEstablishment/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtEstablishment/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtEstablishment/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtEstablishment/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtCaseStatus",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtCaseStatus")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtCaseStatus/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtCaseStatus/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/CourtCaseStatus/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/CourtCaseStatus/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/RegistrationFees",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/RegistrationFee")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/RegistrationFees/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/RegistrationFee/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/RegistrationFees/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/RegistrationFee/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/RegistrationFeesRate",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/RegistrationFeesRate")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/RegistrationFeesRate/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/RegistrationFeesRate/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/RegistrationFeesRate/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/RegistrationFeesRate/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Liaison_Process_Exception",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessException")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Liaison_Process_Exception/Form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessException/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Liaison_Process_Exception/Edit_Form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessException/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LiaisonProcessDependency",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessDependency")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LiaisonProcessDependency/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessDependency/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LiaisonProcessDependency/Edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessDependency/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/apf_panel",
    Component: React.lazy(() => import("../../Pages/Master/BDMaster/ApfPanel")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/apf_panel/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ApfPanel/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/apf_panel/Edit_from",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ApfPanel/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/apfTask",
    Component: React.lazy(() => import("../../Pages/Master/BDMaster/ApfTask")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/apfTask/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ApfTask/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/apfTask/form-edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ApfTask/Editform")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BD",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/BdMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Bank",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/BankMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Liaison",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/LiaisonMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Legal",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/LegalMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/General",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/GeneralMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Civil-Master/Civil",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/CivilMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/project_document_type",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectDocumentType")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/project_document_type/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectDocumentType/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/project_document_type/edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectDocumentType/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/HR",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/HRMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Finance",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/FinanceMaster")
    ),
    exact: true,
    isNavbar: true
  },
  //
  {
    path: "/BD-Master/TaskDelayReason",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/TaskDelayReason")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/TaskDelayReason/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/TaskDelayReason/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/TaskDelayReason/Edit_from",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/TaskDelayReason/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BankMaster",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BankMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BankMaster/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BankMaster/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BankMaster/form_edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BankMaster/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LoanTask",
    Component: React.lazy(() => import("../../Pages/Master/BDMaster/LoanTask")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LoanTask/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanTask/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/LoanTask/edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanTask/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil-master/wbs",
    Component: React.lazy(() => import("../../Pages/Master/CivilMaster/WBS")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity",
    Component: React.lazy(() => import("../../Pages/Civil/Activity")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskTransaction",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskTransaction")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskTransaction/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskTransaction/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectTaskTransaction/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectTaskTransaction/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectDropReason",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectDropReason")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectDropReason/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectDropReason/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectDropReason/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectDropReason/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanPanel",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanPanel")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanPanel/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanPanel/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanPanel/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanPanel/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanProcess",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanProcess")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanProcess/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanProcess/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanProcess/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanProcess/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BdTaskApproval",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BdTaskApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BdTaskApproval/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BdTaskApproval/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BdTaskApproval/Edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/BdTaskApproval/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan/project-charter-detail",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectPlan/ProjectCharterPrint")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectPlan/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/wbs",
    Component: React.lazy(() => import("../../Pages/Civil/WBS")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/wbs/new",
    Component: React.lazy(() => import("../../Pages/Civil/WBS/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/SubRegistrarOfficeList",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/SubRegistrarOffice")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/SubRegistrarOfficeList/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/SubRegistrarOffice/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/SubRegistrarOfficeList/Edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/SubRegistrarOffice/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BdSubRegistrarMapping",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/SubRegistrarMapping")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BdSubRegistrarMapping/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/SubRegistrarMapping/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/BdSubRegistrarMapping/Edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/SubRegistrarMapping/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/new",
    Component: React.lazy(() =>
      import("../../Pages/Civil/Activity/ActivityAddForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonProcessApplicabilityReason",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LiaisonProcessApplicabilityReason")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonProcessApplicabilityReason/new",
    Component: React.lazy(() =>
      import(
        "../../Pages/Master/BDMaster/LiaisonProcessApplicabilityReason/form"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LiaisonProcessApplicabilityReason/edit",
    Component: React.lazy(() =>
      import(
        "../../Pages/Master/BDMaster/LiaisonProcessApplicabilityReason/edit"
      )
    ),
    exact: true,
    isNavbar: true
  },
  /* -------------------------------------------------------------------------- */
  /*                          // sadanand civil master                          */
  /* -------------------------------------------------------------------------- */
  {
    path: "/civil/object-output-list",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ObjectOutputList")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/object-output-list/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ObjectOutputList/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/object-output-list/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ObjectOutputList/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-process",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectProcess")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-process/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectProcess/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-process/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectProcess/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectTask")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectTask/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectTask/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-role",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectRole")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-role/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectRole/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-role/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectRole/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-uom",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectTaskUOM")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-uom/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectTaskUOM/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-uom/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilProjectTaskUOM/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-resource-type",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilResourceType")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-resource-type/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilResourceType/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-resource-type/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilResourceType/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/projectResource",
    Component: React.lazy(() => import("../../Pages/Civil/CivilResource")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/projectResource/new",
    Component: React.lazy(() => import("../../Pages/Civil/CivilResource/form")),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/civil/projectResource/new-material',
  // 	Component: React.lazy(() => import('../../Pages/Civil/CivilResource/materialForm')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  {
    path: "/civil/projectResource/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilResource/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/Accounts",
    Component: React.lazy(() =>
      import("../../Pages/Master/MasterList/AccountMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeeting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeeting/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeeting/Review")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting/edit",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeeting/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/object-output-list/copy",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ObjectOutputList/Projectcopy")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/projectResource/copy",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilResource/Projectcopy")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/RoleFunctionality",
    Component: React.lazy(() => import("../../Pages/HR/RoleFunctionality")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/RoleFunctionality/new",
    Component: React.lazy(() =>
      import("../../Pages/HR/RoleFunctionality/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/RoleFunctionality/edit",
    Component: React.lazy(() =>
      import("../../Pages/HR/RoleFunctionality/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/PublicHoliday",
    Component: React.lazy(() => import("../../Pages/HR/PublicHoliday")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/PublicHoliday/new",
    Component: React.lazy(() => import("../../Pages/HR/PublicHoliday/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/PublicHoliday/edit",
    Component: React.lazy(() => import("../../Pages/HR/PublicHoliday/edit")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-plan/project-charter-add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectPlan/ProjectCharterAddForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-task-plan-activity/quantity-planning-form",
    Component: React.lazy(() =>
      import(
        "../../Pages/Civil/CivilProjectTaskPlanActivity/QuantityPlanningFromTaskPlan"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/doc-edit",
    Component: React.lazy(() => import("../../Pages/HR/docs-edit")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectZone",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectZone")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectZone/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectZone/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectZone/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectZone/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LandLordMeetingParticular",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LandLordMeetingParticular")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LandLordMeetingParticular/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LandLordMeetingParticular/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LandLordMeetingParticular/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LandLordMeetingParticular/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LandLordMeetingStage",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LandLordMeetingStage")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LandLordMeetingStage/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LandLordMeetingStage/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LandLordMeetingStage/Edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LandLordMeetingStage/FormEdit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/SalaryAdvanceRequest",
    Component: React.lazy(() => import("../../Pages/HR/SalaryAdvanceRequest")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/SalaryAdvanceRequest/Form",
    Component: React.lazy(() =>
      import("../../Pages/HR/SalaryAdvanceRequest/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/SalaryAdvanceRequest/Edit_Form",
    Component: React.lazy(() =>
      import("../../Pages/HR/SalaryAdvanceRequest/EditForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/HrOutPass",
    Component: React.lazy(() => import("../../Pages/HR/Hroutpass")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/HrOutPass/Form",
    Component: React.lazy(() => import("../../Pages/HR/Hroutpass/Form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/HrOutPass/Edit_Form",
    Component: React.lazy(() => import("../../Pages/HR/Hroutpass/EditForm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/SalaryAdvanceApproval",
    Component: React.lazy(() => import("../../Pages/HR/SalaryAdvanceApprove")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/SalaryAdvanceApproval/form",
    Component: React.lazy(() =>
      import("../../Pages/HR/SalaryAdvanceApprove/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/SalaryAdvanceIssue",
    Component: React.lazy(() => import("../../Pages/HR/SalaryAdvanceIssue")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/SalaryAdvanceIssue/form",
    Component: React.lazy(() =>
      import("../../Pages/HR/SalaryAdvanceIssue/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/PettyCash",
    Component: React.lazy(() => import("../../Pages/HR/PettyCash")),
    exact: true
  },
  {
    path: "/HR/PettyCash/form",
    Component: React.lazy(() => import("../../Pages/HR/PettyCash/form")),
    exact: true
  },
  {
    path: "/civil/activity-resource",
    Component: React.lazy(() => import("../../Pages/Civil/ActivityResource")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/MOUDateUpdate",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/MOUDateUpdate")
    ),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/civil/activity-resource/new',
  // 	Component: React.lazy(() => import('../../Pages/Civil/ActivityResource/form')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/civil/activity-resource/edit',
  // 	Component: React.lazy(() => import('../../Pages/Civil/ActivityResource/ReviewForm')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  {
    path: "/civil/civil-Task-Budget",
    Component: React.lazy(() => import("../../Pages/Civil/CivilTaskBudget")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-Task-Budget/add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskBudget/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-Task-Budget/detail",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskBudget/CivilTaskBudgetDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-wise-budget",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskWiseBudgetReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-wise-budget/add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskWiseBudgetReport/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-wise-budget/update",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskWiseBudgetReport/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/budget-approval",
    Component: React.lazy(() =>
      import("../../Pages/Civil/Activity/BudgetSendForApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/project-schedule",
    Component: React.lazy(() =>
      import("../../Pages/Civil/Activity/ProjectScheduleSendForApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/quantity-estimation",
    Component: React.lazy(() =>
      import("../../Pages/Civil/Activity/QuantityEstimationSendForApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-budget",
    Component: React.lazy(() => import("../../Pages/Civil/CivilProjectBudget")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-wbs-budget",
    Component: React.lazy(() => import("../../Pages/Civil/CivilWbsBudget")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-activity-budget",
    Component: React.lazy(() => import("../../Pages/Civil/CivilTaskBudget")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-bills",
    Component: React.lazy(() => import("../../Pages/Civil/CivilProjectBill")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-bills/print",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectBill/ProjectBillPrint")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-bills/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectBill/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-dependency-exception",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonDependencyException")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-dependency-exception/add",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LiaisonDependencyException/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liaison-dependency-exception/view",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiaisonDependencyException/ReviewForm"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/enquiry-project",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/EnquiryProject")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/enquiry-project/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/EnquiryProject/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/enquiry-project/editForm",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/EnquiryProject/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-schedule",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectSchedule")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeetingReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeetingReport/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeetingReport/Review")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report/edit",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeetingReport/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/gantt-trial",
    Component: React.lazy(() =>
      import("../../Pages/Civil/GanttSyncfusion/Gantt")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/gantt-chart",
    Component: React.lazy(() =>
      import("../../Pages/Civil/GanttSyncfusion/GanttChart")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Designation",
    Component: React.lazy(() => import("../../Pages/HR/Designation")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Designation/new",
    Component: React.lazy(() => import("../../Pages/HR/Designation/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Designation/edit",
    Component: React.lazy(() => import("../../Pages/HR/Designation/edit")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Designation/form-new",
    Component: React.lazy(() => import("../../Pages/HR/Designation/form1")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Department",
    Component: React.lazy(() => import("../../Pages/HR/Department")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Department/new",
    Component: React.lazy(() => import("../../Pages/HR/Department/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Department/edit",
    Component: React.lazy(() => import("../../Pages/HR/Department/edit")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Level",
    Component: React.lazy(() => import("../../Pages/HR/Level")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Level/new",
    Component: React.lazy(() => import("../../Pages/HR/Level/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Level/edit",
    Component: React.lazy(() => import("../../Pages/HR/Level/edit")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/HR/Designation/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/HR/Designation/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeetingReport/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeetingReport/Review")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report/edit",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandLordMeetingReport/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/landlord-meeting-report/print",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LandLordMeetingReport/LandLordMeetingReportPrint"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-master/ProjectCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-master/ProjectCategory/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-master/ProjectCategory/edit_form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectCategory/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderWBS",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WorkOrderWBS")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderWBS/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WorkOrderWBS/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderWBS/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WorkOrderWBS/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderActivity",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WorkOrderActivity")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderActivity/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WorkOrderActivity/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderActivity/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WorkOrderActivity/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityGroup",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityGroup")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityGroup/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityGroup/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityGroup/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityGroup/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityCategory/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityCategory/editform",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityCategory/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityItems",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityItems")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityItems/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityItems/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProfitabilityItems/form-edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProfitabilityItems/Edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/mou-kpi-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/MOU_KPI_REPORT")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-acquisition-kpi-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LAND_ACQUISITION_KPI_REPORT")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/ProjectPhase",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectPhase")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/ProjectPhase/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectPhase/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/BD-Master/ProjectPhase/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectPhase/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/po-rejection-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/PurchaseOrderRejectionReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/po-cycle-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/PurchaseOrderCycleReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/ProfitabilityReport",
    Component: React.lazy(() =>
      import("../../Pages/Civil/ProfitabilityReport")
    ),
    exact: true,
    isNavbar: true
  },
  /* -------------------------------------------------------------------------- */
  /*                                 // sadanand                                */
  /* -------------------------------------------------------------------------- */
  {
    path: "/civil/civil-work-order",
    Component: React.lazy(() => import("../../Pages/Civil/WorkOrder")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-work-order/add",
    Component: React.lazy(() => import("../../Pages/Civil/WorkOrder/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-work-order/view",
    Component: React.lazy(() =>
      import("../../Pages/Civil/WorkOrder/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-work-order/edit",
    Component: React.lazy(() => import("../../Pages/Civil/WorkOrder/editForm")),
    exact: true,
    isNavbar: true
  },
  // nikhil

  {
    path: "/civil/WorkOrderItemCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WOItemCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderItemCategory/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WOItemCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/WorkOrderItemCategory/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/WOItemCategory/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualEntry")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual/machine-entry",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualEntry/MachineEntryForm-old")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual/manpower-entry",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualEntry/ManpowerEntryForm-old")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual/contract-entry",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualEntry/ContractEntryForm-old")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/crm-tat",
    Component: React.lazy(() => import("../../Pages/Dashboard/CrmTAT")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/bd-overall-report",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/BdOverallReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/civil-resource-report-non-material",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CivilResourceReportNonMaterial")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/civil-resource-report-material",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/CivilResourceReportMaterial")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/project/project-split",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/Project/SplitProjectForm")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/accounts/project-mortgage",
    Component: React.lazy(() => import("../../Pages/Accounts/ProjectMortgage")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-mortgage/new",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectMortgage/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-mortgage/view",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectMortgage/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-machine",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectMachine")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-machine/add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectMachine/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-machine/view",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectMachine/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-machine/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectMachine/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/vendor-machine",
    Component: React.lazy(() => import("../../Pages/Civil/VendorMachine")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/vendor-machine/add",
    Component: React.lazy(() => import("../../Pages/Civil/VendorMachine/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/vendor-machine/view",
    Component: React.lazy(() =>
      import("../../Pages/Civil/VendorMachine/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/vendor-machine/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/VendorMachine/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanProcessStatus",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanProcessStatus")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanProcessStatus/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanProcessStatus/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/LoanProcessStatus/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/LoanProcessStatus/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-loan",
    Component: React.lazy(() => import("../../Pages/Accounts/ProjectLoan")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-loan/new",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectLoan/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-loan/view",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectLoan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-loan/edit",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectLoan/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-loan-process",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectLoanProcess")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-loan-process/view",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectLoanProcess/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/project-loan-process/edit",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ProjectLoanProcess/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/loan-query",
    Component: React.lazy(() => import("../../Pages/Accounts/LoanQuery")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/loan-query/add",
    Component: React.lazy(() => import("../../Pages/Accounts/LoanQuery/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/loan-query/view",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/LoanQuery/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/civil/civil-task-actual-machine",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualMachine")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-machine/measurement-update",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualMachine/MeasurementUpdateForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-machine/review-form",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualMachine/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-machine/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualMachine/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-machine/qc-review",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualMachine/QualityCheckReview")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/civil/civil-task-actual-machine/machine-add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualMachine/MachineEntryForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/activity-quality-check-point",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ActivityQualityCheck")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/activity-quality-check-point/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ActivityQualityCheck/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/activity-quality-check-point/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ActivityQualityCheck/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/actual-reject-reason",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ActualRejectReason")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/actual-reject-reason/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ActualRejectReason/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/actual-reject-reason/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ActualRejectReason/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  // Vendor Nithish ------- start
  {
    path: "/Procurement/vendor",
    Component: React.lazy(() => import("../../Pages/Accounts/Vendor")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Procurement/vendor/form",
    Component: React.lazy(() => import("../../Pages/Accounts/Vendor/Form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Procurement/vendor/form-edit",
    Component: React.lazy(() => import("../../Pages/Accounts/Vendor/edit")),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/Procurement/vendorDocument',
  // 	Component: React.lazy(() => import('../../Pages/Accounts/Vendor/VendorDocument')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/Procurement/VendorService',
  // 	Component: React.lazy(() => import('../../Pages/Accounts/Vendor/VendorService')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/Procurement/VendorContact',
  // 	Component: React.lazy(() => import('../../Pages/Accounts/Vendor/VendorContact')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/Procurement/VendorAccount',
  // 	Component: React.lazy(() => import('../../Pages/Accounts/Vendor/VendorAccount')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/Procurement/VendorAddress',
  // 	Component: React.lazy(() => import('../../Pages/Accounts/Vendor/VendorAddress')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  {
    path: "/Procurement/VendorDetails",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/Vendor/VendorDetails")
    ),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/Procurement/VendorMsme',
  // 	Component: React.lazy(() => import('../../Pages/Accounts/Vendor/VendorMsme')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // Vendor Nithish ------- end
  {
    path: "/civil/civil-task-actual-manpower",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualManpower")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-manpower/review-form",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualManpower/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-manpower/measurement-update",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualManpower/MeasurementUpdateForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-manpower/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualManpower/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-manpower/qc-review",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualManpower/QualityCheckReview")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-manpower/manpower-add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualManpower/ManpowerEntryForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-contract",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualContract")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-contract/measurement-update",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualContract/MeasurementUpdateForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-contract/qc-review",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualContract/QualityCheckReview")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-contract/review-form",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualContract/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-contract/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualContract/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-contract/contract-add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualContract/ContractEntryForm")
    ),
    exact: true,
    isNavbar: true
  },
  // {
  // 	path: '/tempSelect',
  // 	Component: React.lazy(() => import('../../Pages/Test/TempSelect')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  // {
  // 	path: '/tempSelect2',
  // 	Component: React.lazy(() => import('../../Pages/Test/TempSelect2')),
  // 	exact: true,
  // 	isNavbar: true
  // },
  {
    path: "/civil/civil-task-actual-machine/generate-bill",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilTaskActualMachine/GenerateBillForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-manpower/generate-bill",
    Component: React.lazy(() =>
      import(
        "../../Pages/Civil/CivilTaskActualManpower/GenerateBillFormManpower"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/vendor-change-request",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorChangeRequest")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/vendor-change-request-detail/detail",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorChangeRequest/detail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-task-actual-contract/generate-bill",
    Component: React.lazy(() =>
      import(
        "../../Pages/Civil/CivilTaskActualContract/GenerateBillFormContract"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/EPA",
    Component: React.lazy(() => import("../../Pages/HR/EPA")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/EPA/add-hor",
    Component: React.lazy(() => import("../../Pages/HR/EPA/form_hor")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/EPA/preivew",
    Component: React.lazy(() => import("../../Pages/HR/EPA/previewForm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestion")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestion/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question/desgination",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestion/DesignationList")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question/option-edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestion/optionEdit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question/view",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestion/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question-option",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestionOption")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question-option/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestionOption/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal-question-option/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalQuestionOption/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/stock-masters/material-master",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/MaterialMaster")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/stock-masters/material-master/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/MaterialMaster/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/Appraisal")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/Appraisal/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/appraisal/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/Appraisal/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/ItemGroup",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ItemGroup")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/ItemGroup/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ItemGroup/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/ItemGroup/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/ItemGroup/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/Material",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/Material")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/Material/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/Material/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/Material/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/Material/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/booking-form",
    Component: React.lazy(() => import("../../Pages/Sales/BookingForm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/booking-form/form",
    Component: React.lazy(() => import("../../Pages/Sales/BookingForm/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/booking-form/review",
    Component: React.lazy(() => import("../../Pages/Sales/BookingForm/Review")),
    exact: true,
    isNavbar: true
  },
  //procument master
  {
    path: "/prc-master/ItemGroup",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/ItemGroup")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/ItemGroup/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/ItemGroup/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/ItemGroup/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/ItemGroup/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/StockUOM",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/StockUOM")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/StockUOM/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/StockUOM/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/StockUOM/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/StockUOM/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/AssetGroup",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/AssetGroup")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/AssetGroup/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/AssetGroup/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/AssetGroup/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/AssetGroup/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/AssetType",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/AssetType")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/AssetType/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/AssetType/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/AssetType/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/AssetType/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/project-material-estimate",
    Component: React.lazy(() =>
      import("../../Pages/Civil/ProjectMaterialEstimate")
    ),
    exact: true,
    isNavbar: true
  },

  //civil/civil-project-activity/approval  not in use
  {
    path: "/civil/civil-project-activity/approval",
    Component: React.lazy(() =>
      import("../../Pages/Civil/Activity/ProjectScheduleSendForApproval_old")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/project-material-procurement-schedule",
    Component: React.lazy(() =>
      import("../../Pages/Civil/ProjectMaterialProcurementSchedule")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/project-material-procurement-schedule/view",
    Component: React.lazy(() =>
      import("../../Pages/Civil/ProjectMaterialProcurementSchedule/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/project-material-procurement-schedule/send-indent",
    Component: React.lazy(() =>
      import(
        "../../Pages/Civil/ProjectMaterialProcurementSchedule/ScheduleSendForIndent"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/indent-item",
    Component: React.lazy(() => import("../../Pages/Procurement/IndentItem")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/indent-item/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/IndentItem/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/indent-item/approval",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/IndentItem/IndentSendForApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/indent-item/new",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/IndentItem/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/enquiry-profile",
    Component: React.lazy(() => import("../../Pages/CRM/EnquiryProfile/index")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/enquiry-profile/new",
    Component: React.lazy(() => import("../../Pages/CRM/EnquiryProfile/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/enquiry-profile/view",
    Component: React.lazy(() =>
      import("../../Pages/CRM/EnquiryProfile/Review")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/enquiry-profile/edit",
    Component: React.lazy(() =>
      import("../../Pages/CRM/EnquiryProfile/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/enquiry-profile/Feedback",
    Component: React.lazy(() =>
      import("../../Pages/CRM/EnquiryProfile/feedbackForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/AppraisalCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/AppraisalCategory/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/AppraisalCategory/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalCategory/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/AppraisalRating",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalRating")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/AppraisalRating/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalRating/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/AppraisalRating/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/AppraisalRating/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/liason-documentation/bulk_document_form",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LiasonDocumentation/AddBulkDocuments"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation",
    Component: React.lazy(() => import("../../Pages/Procurement/Quotation")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation/new",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/Quotation/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/Quotation/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation/approval",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/Quotation/QuotationSendForApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/Question",
    Component: React.lazy(() => import("../../Pages/Master/HRMaster/Question")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/Question/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/Question/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/Question/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/Question/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/QuestionOptionValue",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/QuestionOptionValue")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/QuestionOptionValue/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/QuestionOptionValue/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/QuestionOptionValue/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/QuestionOptionValue/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/QuestionOption",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/QuestionOption")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/QuestionOption/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/QuestionOption/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/QuestionOption/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/QuestionOption/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-order",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseOrder")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-order/request",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseOrder/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-order/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseOrder/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-order/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseOrder/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/grn-items",
    Component: React.lazy(() => import("../../Pages/Procurement/GRN")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/grn-items/add",
    Component: React.lazy(() => import("../../Pages/Procurement/GRN/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/grn-items/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/GRN/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/grn-items/editForm",
    Component: React.lazy(() => import("../../Pages/Procurement/GRN/editForm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/site-visit-feedback",
    Component: React.lazy(() => import("../../Pages/CRM/SiteVisitFeedback")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/site-visit-feedback/view",
    Component: React.lazy(() =>
      import("../../Pages/CRM/SiteVisitFeedback/Review")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/LeaveType",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/LeaveType")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/LeaveType/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/LeaveType/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/LeaveType/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/LeaveType/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/project-drawing",
    Component: React.lazy(() => import("../../Pages/Civil/CivilDrawing")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/project-drawing/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilDrawing/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd/Document-Repository",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/DocumentRepository")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/dashboard/budget/civil-project",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/CivilBudgetProgress/ProjectProgress")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/dashboard/budget/civil-project/projectProgressDetail",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/CivilBudgetProgress/projectProgressDetail")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/dashboard/budget/civil-project/projectProgressTaskDetail",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/CivilBudgetProgress/taskProgressDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/enquiry-profile/form/:flag/:EnquiryID/:LoginuserId",
    Component: React.lazy(() =>
      import("../../Pages/Share/EnquiryProfileShare")
    ),
    exact: true,
    isNavbar: false
  },
  {
    path: "/enquiry-profile/form/:flag/:EnquiryID/:LoginuserId/thankyou",
    Component: React.lazy(() =>
      import("../../Pages/Share/EnquiryProfileShare/thankyouPage")
    ),
    exact: true,
    isNavbar: false
  },
  {
    path: "/enquiry-profile/Feedback/:EnquiryID/:LoginuserId",
    Component: React.lazy(() => import("../../Pages/Share/ShareFeedbackForm")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/enquiry-profile/Feedback/:EnquiryID/:LoginuserId/thankyou",
    Component: React.lazy(() =>
      import("../../Pages/Share/ShareFeedbackForm/thankyouPage")
    ),
    exact: true,
    isNavbar: false
  },
  {
    path: "/civil/civil-work-inspection",
    Component: React.lazy(() => import("../../Pages/Civil/WorkInspection")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-work-inspection/view",
    Component: React.lazy(() =>
      import("../../Pages/Civil/WorkInspection/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-work-inspection-pending/add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilWorkInspectionPending/createForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-work-inspection-pending/view",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilWorkInspectionPending/ReviewForm.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-work-inspection-pending",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilWorkInspectionPending")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-training",
    Component: React.lazy(() => import("../../Pages/Civil/QcTraning")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-training/add",
    Component: React.lazy(() => import("../../Pages/Civil/QcTraning/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-training/view",
    Component: React.lazy(() =>
      import("../../Pages/Civil/QcTraning/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-training/edit",
    Component: React.lazy(() => import("../../Pages/Civil/QcTraning/editForm")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd/Project-file-move",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/ProjectFileMove")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/TodoCategory",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/TodoCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/TodoCategory/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/TodoCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr-master/TodoCategory/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/TodoCategory/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/Material/detail",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/Material/details.jsx")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/bd-masters/ProjectSubTask",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectSubTask")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectSubTask/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectSubTask/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/bd-masters/ProjectSubTask/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectSubTask/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/cp-meeting",
    Component: React.lazy(() => import("../../Pages/CRM/CP-Meeting")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/cp-meeting/add",
    Component: React.lazy(() => import("../../Pages/CRM/CP-Meeting/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/cp-meeting/detail",
    Component: React.lazy(() =>
      import("../../Pages/CRM/CP-Meeting/Reviewform")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/cp-meeting/edit",
    Component: React.lazy(() => import("../../Pages/CRM/CP-Meeting/edit")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/Preferred-Location",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmPreferredLocation")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/Preferred-Location/form",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmPreferredLocation/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/Preferred-Location/form-edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmPreferredLocation/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-source-group",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSourceGroup")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-source-group/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSourceGroup/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-source-group/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSourceGroup/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-source-category",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSourceCategory")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-source-category/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSourceCategory/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-source-category/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSourceCategory/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-source",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSource")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-source/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSource/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-source/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSource/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-status",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquiryStatus")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-status/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquiryStatus/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-status/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquiryStatus/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-category",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquirycategory")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-category/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquirycategory/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-category/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquirycategory/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-call-status",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquiryCallStatus")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-call-status/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquiryCallStatus/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-enquiry-call-status/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmEnquiryCallStatus/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/indent-item/edit",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/IndentItem/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation/edit",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/Quotation/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  //commit
  {
    path: "/civil/project-material-procurement-schedule/send-approval",
    Component: React.lazy(() =>
      import(
        "../../Pages/Civil/ProjectMaterialProcurementSchedule/ScheduleSendForApproval"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/indent-approval",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/IndentApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/indent-approval/approval",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/IndentApproval/IndentSendForApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation-approval",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/QuotationApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation-approval/approval",
    Component: React.lazy(() =>
      import(
        "../../Pages/Procurement/QuotationApproval/QuotationSendForApproval"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/quotation-approval/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/QuotationApproval/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-order-approval",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseOrderApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-order-approval/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseOrderApproval/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/crm-dimension",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmDimensionMaster")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-dimension/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmDimensionMaster/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-dimension/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmDimensionMaster/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-release-status",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmReleaseStatus")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-release-status/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmReleaseStatus/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-release-status/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmReleaseStatus/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-premium-type",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSitePremiumType")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-premium-type/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSitePremiumType/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-premium-type/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSitePremiumType/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-status",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSiteStatus")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-status/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSiteStatus/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-status/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSiteStatus/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-investor",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSiteInvestor")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-investor/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSiteInvestor/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-site-investor/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmSiteInvestor/edit")
    ),
    exact: true,
    isNavbar: true
  },
  //BD Task Approval - (Budget,Quantity,Schedule) Start
  {
    path: "/civil/civil-project-activity/budget-approval/approval",
    Component: React.lazy(() => import("../../Pages/Civil/BudgetApproval")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/quantity-approval/approval",
    Component: React.lazy(() => import("../../Pages/Civil/QuantityApproval")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-activity/schedule-approval/approval",
    Component: React.lazy(() => import("../../Pages/Civil/ScheduleApproval")),
    exact: true,
    isNavbar: true
  },
  //BD Task Approval - (Budget,Quantity,Schedule) End
  {
    path: "/crm/crm-block-reason",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmBlockReason")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-block-reason/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmBlockReason/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/crm/crm-block-reason/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CrmMaster/CrmBlockReason/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/stock-statement",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/StockStatment")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/stock-statement/details",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/StockStatment/stockTransaction")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/grn-items/inspection",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/GRN/InspectionForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/GrnCheckPointMaterial",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/GrnCheckPointMaterial")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/GrnCheckPointMaterial/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/GrnCheckPointMaterial/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/GrnCheckPointMaterial/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/GrnCheckPointMaterial/edit")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/prc-master/GrnCheckPoint",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/GrnCheckPoint")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/GrnCheckPoint/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/GrnCheckPoint/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/GrnCheckPoint/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/GrnCheckPoint/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-bills",
    Component: React.lazy(() => import("../../Pages/Civil/CivilProjectBill")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-bills/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectBill/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-project-bills/detail",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectBill/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/civil-bill",
    Component: React.lazy(() => import("../../Pages/Accounts/CivilBill")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/civil-bill/detail",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/CivilBill/ReviewForm.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/civil-bill/edit",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/CivilBill/editForm.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/material-request",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/MaterialRequest")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/material-request/add",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/MaterialRequest/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/material-request/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/MaterialRequest/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/material-request/edit",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/MaterialRequest/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/store/stock-transfer",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/StockTransfer")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/store/stock-transfer/edit",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/StockTransfer/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/store/stock-transfer/add",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/StockTransfer/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/store/stock-transfer/review-form",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/StockTransfer/reviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-setting/master",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilSetting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-setting/master/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilSetting/add")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-setting/master/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/CivilMaster/CivilSetting/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-Project-setting",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectSetting")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-Project-setting/add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectSetting/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-Project-setting/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectSetting/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/civil-Project-setting/ReviewForm",
    Component: React.lazy(() =>
      import("../../Pages/Civil/CivilProjectSetting/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-rejection",
    Component: React.lazy(() => import("../../Pages/Civil/QcRejection")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-rejection/measurement-update",
    Component: React.lazy(() =>
      import("../../Pages/Civil/QcRejection/MeasurementUpdateForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-rejection/qc-review",
    Component: React.lazy(() =>
      import("../../Pages/Civil/QcRejection/QualityCheckReview")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-rejection/review-form",
    Component: React.lazy(() =>
      import("../../Pages/Civil/QcRejection/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-rejection/edit",
    Component: React.lazy(() =>
      import("../../Pages/Civil/QcRejection/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-rejection/contract-add",
    Component: React.lazy(() =>
      import("../../Pages/Civil/QcRejection/QcRejectionEntryForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/civil/qc-rejection/generate-bill",
    Component: React.lazy(() =>
      import("../../Pages/Civil/QcRejection/GenerateBillFormQcRejection")
    ),
    exact: true,
    isNavbar: true
  },
  // purchase-invoice
  {
    path: "/procurement/purchase-invoice",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseInvoice")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-invoice/request",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseInvoice/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-invoice/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseInvoice/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-invoice/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseInvoice/editForm")
    ),
    exact: true,
    isNavbar: true
  },

  // Purchase Invoice Approval
  {
    path: "/accounts/purchase-invoice-approval",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PurchaseInvoiceApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/purchase-invoice-approval/detail",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PurchaseInvoiceApproval/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },

  //Channel Partner
  {
    path: "/Procurement/channel-partner",
    Component: React.lazy(() => import("../../Pages/Accounts/ChannelPartner")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Procurement/channel-partner/form",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ChannelPartner/Form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Procurement/channel-partner/edit",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ChannelPartner/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Procurement/channel-partner/review-form",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ChannelPartner/ChannelPartnerDetails")
    ),
    exact: true,
    isNavbar: true
  },
  //EPA Sharable
  {
    path: "/EPA-Add/form/:EpaID/:LoginuserId",
    Component: React.lazy(() => import("../../Pages/Share/EPAShare")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/EPA-Add/preview/:EpaID/:LoginuserId",
    Component: React.lazy(() =>
      import("../../Pages/Share/EPAShare/previewForm")
    ),
    exact: true,
    isNavbar: false
  },
  {
    path: "/EPA-Add/form/:EpaID/:LoginuserId/thankyou",
    Component: React.lazy(() =>
      import("../../Pages/Share/EPAShare/thankyouPage")
    ),
    exact: true,
    isNavbar: false
  },
  //Land-registration
  {
    path: "/business-development/land-registration",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-registration/new",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-registration/approval",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees/approval")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/business-development/land-registration/issued",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees/issue")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/business-development/land-registration/draft",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees/draft")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/business-development/land-registration/request",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees/request")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/business-development/land-registration/approve",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees/approve")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/business-development/land-registration/settlementRequest",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LandRegistrationFees/settlementRequest"
      )
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/business-development/land-registration/settlementApprove",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LandRegistrationFees/settlementApprove"
      )
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/business-development/land-registration/issueNonRefund",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/LandRegistrationFees/issueNonRefund"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/land-registration/detail",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/LandRegistrationFees/detail")
    ),
    exact: true,
    isNavbar: true
  },

  //site-registration
  {
    path: "/business-development/site-registration",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/form",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/edit",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/view",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration/reviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/settlement-Approval",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/SiteRegistration/settlementApproval"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/request",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration/request")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/issueNonRefund",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration/issueNonRefund")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/issued",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration/issue")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/settlementRequest",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/SiteRegistration/settlementRequest"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/settlementApprove",
    Component: React.lazy(() =>
      import(
        "../../Pages/BusinessDevelopment/SiteRegistration/settlementApprove"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/business-development/site-registration/detail",
    Component: React.lazy(() =>
      import("../../Pages/BusinessDevelopment/SiteRegistration/detail")
    ),
    exact: true,
    isNavbar: true
  },
  //Delivery-Challan
  {
    path: "/procurement/delivery-challan-request",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/DeliveryChallan")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/delivery-challan-request/request",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/DeliveryChallan/DcTransferRequest")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/delivery-challan-request/add",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/DeliveryChallan/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/delivery-challan-request/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/DeliveryChallan/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/delivery-challan-request/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/DeliveryChallan/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/delivery-challan-approved",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/DeliveryChallanApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/delivery-challan-approved/view",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/DeliveryChallanApproval/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  //Channel-Partner-Report
  {
    path: "/reports/channel-partner-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReport/index")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report/leads",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReport/Leads")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report/site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReport/SiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report/sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReport/Sales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReport/Cancellation")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/reports/channel-partner-report-detail",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReportDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report-detail/leads",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReportDetail/Leads")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report-detail/site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReportDetail/SiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report-detail/sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReportDetail/Sales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-report-detail/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPSalesReportDetail/Cancellation")
    ),
    exact: true,
    isNavbar: true
  },
  //Source-Sales-Report
  {
    path: "/reports/source-sales-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report/leads",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReport/Leads")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report/site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReport/SiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report/sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReport/Sales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReport/Cancellation")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report-detail",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReportDetail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report-detail/leads",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReportDetail/Leads")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report-detail/site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReportDetail/SiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report-detail/sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReportDetail/Sales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/source-sales-report-detail/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SourcesSalesReportDetail/Cancellation")
    ),
    exact: true,
    isNavbar: true
  },
  //Channel-Partner-Onboard
  {
    path: "/reports/channel-partner-onboard",
    Component: React.lazy(() => import("../../Pages/Reports/CPOnboard")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-onboard/cp-detail",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPOnboard/CPOnboardDetails")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-onboard/leads",
    Component: React.lazy(() => import("../../Pages/Reports/CPOnboard/Leads")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-onboard/site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPOnboard/SiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-onboard/sales",
    Component: React.lazy(() => import("../../Pages/Reports/CPOnboard/Sales")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/channel-partner-onboard/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CPOnboard/Cancellation")
    ),
    exact: true,
    isNavbar: true
  },
  //Pre-Sales-Report
  {
    path: "/reports/pre-sales-report",
    Component: React.lazy(() => import("../../Pages/Reports/PreSalesReport")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-leads",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReport/Leads")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/reports/pre-sales-report/over-due-lead",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReport/OverDueLead")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-detail/lead",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails/Leads")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-detail/overDue",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails/OverDueReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-details",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/reports/pre-sales-report-actual-site-visit-pevious-enquiry",
    Component: React.lazy(() =>
      import(
        "../../Pages/Reports/PreSalesReportDetails/SiteVisitPreviousEnquiry"
      )
    ),
    exact: true,
    isNavbar: true
  },
  //Liaison-Master
  {
    path: "/liaison-Master/ProjectProcessMap",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectProcessMap")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/liaison-Master/ProjectProcessMap/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectProcessMap/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison-Master/ProjectProcessMap/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/ProjectProcessMap/edit")
    ),
    exact: true,
    isNavbar: true
  },
  //Notification todo and Process
  {
    path: "/todo-pending/:LoginuserId",
    Component: React.lazy(() => import("../../Pages/Share/TodoShare")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/todo-pending-detail/:LoginuserId/:id",
    Component: React.lazy(() =>
      import("../../Pages/Share/TodoShare/TodoDetail")
    ),
    exact: true,
    isNavbar: false
  },
  {
    path: "/bd-pending-task/:LoginuserId",
    Component: React.lazy(() => import("../../Pages/Share/BDTaskShare")),
    exact: true,
    isNavbar: false
  },
  {
    path: "/bd-pending-task-detail/:LoginuserId/:id",
    Component: React.lazy(() =>
      import("../../Pages/Share/BDTaskShare/BDTaskShareDetail")
    ),
    exact: true,
    isNavbar: false
  },
  {
    path: "/Liaison-process-share/:LoginuserId",
    Component: React.lazy(() =>
      import("../../Pages/Share/LiaisonProcessShare")
    ),
    exact: true,
    isNavbar: false
  },
  {
    path: "/Liaison-process-share-detail/:LoginuserId/:id",
    Component: React.lazy(() =>
      import(
        "../../Pages/Share/LiaisonProcessShare/LiaisonProcessShareDetail.jsx"
      )
    ),
    exact: true,
    isNavbar: false
  },
  //goverment-fees
  {
    path: "/accounts/government-fees",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/GovernmentFees/index")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/government-fees/new",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/GovernmentFees/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/government-fees/draft",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/GovernmentFees/draft")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/government-fees/pending",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/GovernmentFees/pending")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/government-fees/approved",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/GovernmentFees/approved")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/government-fees/issued",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/GovernmentFees/issued")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/accounts/government-fees/paid",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/GovernmentFees/paid")
    ),
    exact: true,
    isNavbar: true
  },
  //GovernmentFeesItem
  {
    path: "/liaison-master/GovernmentFeesItem",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/GovernmentFeesItem")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison-master/GovernmentFeesItem/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/GovernmentFeesItem/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/liaison-master/GovernmentFeesItem/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/BDMaster/GovernmentFeesItem/edit")
    ),
    exact: true,
    isNavbar: true
  },
  //retention-percentage
  {
    path: "/Account/retention-percentage",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/RetentionPercentage")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Account/retention-percentage/form",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/RetentionPercentage/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/Account/retention-percentage/edit",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/RetentionPercentage/edit")
    ),
    exact: true,
    isNavbar: true
  },

  //My Dashboard
  {
    path: "/dashboard/main-dashboard",
    Component: React.lazy(() => import("../../Pages/Dashboard/MainDashboard")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/my-dashboard",
    Component: React.lazy(() => import("../../Pages/Dashboard/MyDashboard")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/dashboard/accountable-dashboard",
    Component: React.lazy(() =>
      import("../../Pages/Dashboard/AccountableDashboard")
    ),
    exact: true,
    isNavbar: true
  },
  //Vendor Ledger
  {
    path: "/account/vendor-ledger",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorLedger/index")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/vendor-ledger/form",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorLedger/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/vendor-ledger/PaidAmount",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorLedger/PaidAmount")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/vendor-ledger/PaymentPayable",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorLedger/PaymentPayable")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/vendor-ledger/PaymentStatement",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorLedger/PaymentStatement")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/vendor-ledger-items/Statement",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/VendorLedger/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  //Vendor Payment Request
  {
    path: "/account/payment-request",
    Component: React.lazy(() => import("../../Pages/Accounts/PaymentRequest")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request/form",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequest/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request/reviewform",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequest/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request/edit",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequest/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request/send-for-approval",
    Component: React.lazy(() =>
      import(
        "../../Pages/Accounts/PaymentRequest/PaymentRequestSendForApproval"
      )
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request/approve",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequest/PaymentRequestApprove")
    ),
    exact: true,
    isNavbar: true
  },
  //Purchase Return
  {
    path: "/procurement/purchase-return",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseReturn")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-return/form",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseReturn/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-return/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseReturn/detail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-return/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseReturn/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-return/pending",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseReturn/pending")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/purchase-return/approve",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/PurchaseReturn/approved")
    ),
    exact: true,
    isNavbar: true
  },
  //Payment Request Amount
  {
    path: "/account/payment-request-account",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequestAccount")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request-account/form",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequestAccount/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request-account/reviewform",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequestAccount/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/payment-request-account/pay",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/PaymentRequestAccount/PaymentRequestPay")
    ),
    exact: true,
    isNavbar: true
  },
  //Account Payment Paid
  {
    path: "/accounts/account-payment-tally",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/AccountPaymentTally/index.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/account-payment-tally/detail",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/AccountPaymentTally/ReviewForm.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  //Material Return
  {
    path: "/procurement/material-return",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/MaterialReturn")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/procurement/material-return/detail",
    Component: React.lazy(() =>
      import("../../Pages/Procurement/MaterialReturn/detail")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/crm/follow-up",
    Component: React.lazy(() => import("../../Pages/CRM/FollowUp")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/tds-deduction",
    Component: React.lazy(() => import("../../Pages/Accounts/TdsDeduction")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/tds-deduction/form",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/TdsDeduction/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/account/tds-deduction/edit",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/TdsDeduction/edit")
    ),
    exact: true,
    isNavbar: true
  },
  //Sales Module
  //Sales quotation
  {
    path: "/sales/sales-quotation",
    Component: React.lazy(() => import("../../Pages/Sales/SalesQuotation")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-quotation/request",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesQuotation/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-quotation/detail",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesQuotation/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-quotation/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesQuotation/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  //Sales Quotation Approval
  {
    path: "/sales/sales-quotation-approval",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesQuotationApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-quotation-approval/detail",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesQuotationApproval/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  //sales order
  {
    path: "/sales/sales-order",
    Component: React.lazy(() => import("../../Pages/Sales/SalesOrder")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-order/request",
    Component: React.lazy(() => import("../../Pages/Sales/SalesOrder/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-order/detail",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesOrder/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-order/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesOrder/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  //Sales Order Approval
  {
    path: "/sales/sales-order-approval",
    Component: React.lazy(() => import("../../Pages/Sales/SalesOrderApproval")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-order-approval/detail",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesOrderApproval/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  // Sales Invoice
  {
    path: "/sales/sales-invoice",
    Component: React.lazy(() => import("../../Pages/Sales/SalesInvoice")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-invoice/request",
    Component: React.lazy(() => import("../../Pages/Sales/SalesInvoice/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-invoice/detail",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesInvoice/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-invoice/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesInvoice/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  // Sales Invoice Approval
  {
    path: "/accounts/sales-invoice-approval",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/SalesInvoiceApproval")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/sales-invoice-approval/detail",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/SalesInvoiceApproval/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  //Delivery-Challan
  //  {
  //   path: "/sales/delivery-challan-request",
  //   Component: React.lazy(() =>
  //     import("../../Pages/Sales/DeliveryChallan")
  //   ),
  //   exact: true,
  //   isNavbar: true
  // },
  // {
  //   path: "/sales/delivery-challan-request/request",
  //   Component: React.lazy(() =>
  //     import("../../Pages/Sales/DeliveryChallan/DcTransferRequest")
  //   ),
  //   exact: true,
  //   isNavbar: true
  // },
  // {
  //   path: "/sales/delivery-challan-request/add",
  //   Component: React.lazy(() =>
  //     import("../../Pages/Sales/DeliveryChallan/form")
  //   ),
  //   exact: true,
  //   isNavbar: true
  // },
  // {
  //   path: "/sales/delivery-challan-request/detail",
  //   Component: React.lazy(() =>
  //     import("../../Pages/Sales/DeliveryChallan/ReviewForm")
  //   ),
  //   exact: true,
  //   isNavbar: true
  // },
  // {
  //   path: "/sales/delivery-challan-request/editForm",
  //   Component: React.lazy(() =>
  //     import("../../Pages/Sales/DeliveryChallan/editForm")
  //   ),
  //   exact: true,
  //   isNavbar: true
  // },
  //Purchase Return
  {
    path: "/sales/sales-return",
    Component: React.lazy(() => import("../../Pages/Sales/SalesReturn")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-return/form",
    Component: React.lazy(() => import("../../Pages/Sales/SalesReturn/form")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-return/detail",
    Component: React.lazy(() => import("../../Pages/Sales/SalesReturn/detail")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-return/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesReturn/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-return/pending",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesReturn/pending")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/sales/sales-return/approve",
    Component: React.lazy(() =>
      import("../../Pages/Sales/SalesReturn/approved")
    ),
    exact: true,
    isNavbar: true
  },
  // Expenses Invoice
  {
    path: "/accounts/expenses-invoice",
    Component: React.lazy(() => import("../../Pages/Accounts/ExpensesInvoice")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/expenses-invoice/request",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ExpensesInvoice/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/expenses-invoice/detail",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ExpensesInvoice/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/accounts/expenses-invoice/editForm",
    Component: React.lazy(() =>
      import("../../Pages/Accounts/ExpensesInvoice/editForm")
    ),
    exact: true,
    isNavbar: true
  },
  //HR Master User Bata
  {
    path: "/hr/master/user-bata",
    Component: React.lazy(() => import("../../Pages/Master/HRMaster/UserBata")),
    exact: true,
    isNavbar: true
  },

  {
    path: "/hr/master/user-bata/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/UserBata/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/hr/master/user-bata/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/HRMaster/UserBata/edit")
    ),
    exact: true,
    isNavbar: true
  },
  // {
  //   path: "/report/sales-review-report/actual-agreement",
  //   Component: React.lazy(() =>
  //     import("../../Pages/Reports/SalesReviewReport/ActualAgreement")
  //   ),
  //   exact: true,
  //   isNavbar: true
  // },
  // {
  //   path: "/report/sales-review-report/actual-registration",
  //   Component: React.lazy(() =>
  //     import("../../Pages/Reports/SalesReviewReport/ActualRegistration")
  //   ),
  //   exact: true,
  //   isNavbar: true
  // },
  {
    path: "/report/sales-review-report/actual-agreement",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ActualAgreement")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-review-report/actual-registration",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesReviewReport/ActualRegistration")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/report/sales-team-review-report/actual-registration",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ActualRegistration")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/sales-team-review-report/actual-agreement",
    Component: React.lazy(() =>
      import("../../Pages/Reports/SalesTeamReview/ActualAgreement")
    ),
    exact: true,
    isNavbar: true
  },
  //mar 6 presalesreport
  {
    path: "/reports/pre-sales-report-planned-call",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails/PlannedCall")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-actual-call",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails/ActualCall")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-actual-site-visit",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails/ActualSiteVisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-actual-sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails/ActualSales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/pre-sales-report-cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/PreSalesReportDetails/Cancellation")
    ),
    exact: true,
    isNavbar: true
  },

  //lead report
  {
    path: "/reports/lead-report",
    Component: React.lazy(() => import("../../Pages/Reports/LeadReport")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/lead-report/tbf-detail",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadReport/TbfDetails")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/lead-report/site-lead",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadReport/sitevisit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/lead-report/actual-sales",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadReport/actualsales")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/lead-report/cancellation",
    Component: React.lazy(() =>
      import("../../Pages/Reports/LeadReport/cancellation")
    ),
    exact: true,
    isNavbar: true
  },

  //call summary

  // ----pk----Call-Summary----------
  {
    path: "/report/call-summary-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/index")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/call-summary-report/total-enquiry",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/TotalEnquiry.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/call-summary-report/total-answered",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/TotalAnswered.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/call-summary-report/total-unanswered",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/TotalUnAnswered.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/call-summary-report/follow-up",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/FollowUp.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/call-summary-report/follow-up-unanswered",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/FollowUpUnAnswered.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/call-summary-report/fresh-answered",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/FreshAnswered.jsx")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/report/call-summary-report/fresh-un-answered",
    Component: React.lazy(() =>
      import("../../Pages/Reports/CallSummary/FreshUnAnswered.jsx")
    ),
    exact: true,
    isNavbar: true
  },

  //brand
  {
    path: "/prc-master/brand",
    Component: React.lazy(() => import("../../Pages/Master/Procurement/Brand")),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/brand/new",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/Brand/form")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/prc-master/brand/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/Brand/edit")
    ),
    exact: true,
    isNavbar: true
  },

  //material brand
  {
    path: "/prc-master/material-brand",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/MaterialBrand")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/prc-master/material-brand/add",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/MaterialBrand/form")
    ),
    exact: true,
    isNavbar: true
  },

  {
    path: "/prc-master/material-brand/edit",
    Component: React.lazy(() =>
      import("../../Pages/Master/Procurement/MaterialBrand/edit")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/procurement-report",
    Component: React.lazy(() =>
      import("../../Pages/Reports/ProcurementReport")
    ),
    exact: true,
    isNavbar: true
  },
  {
    path: "/reports/procurement-report/detail",
    Component: React.lazy(() =>
      import("../../Pages/Reports/ProcurementReport/ReviewForm")
    ),
    exact: true,
    isNavbar: true
  },
];
